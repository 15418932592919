<template>
  <div class="c-app">
    <TheSidebar :user-info="userInfo"/>
    <CWrapper class="bg-bg-gray">
      <TheHeader class="bg-bg-gray" :user-info="userInfo" :pageUrl="pageUrl" @triggerChange="triggerChange"/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path" @curPage="curPage" @curUsers="curUsers"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import {mapState} from "vuex";

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
  },
  data(){
    return{
      pageUrl: null,
      usersFetch : null
    }
  },
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  created() {
    // this.pathname = this.$route.pathname;
    this.$store.dispatch("user/GET_USER_INFO");
  },

  methods:{
    curPage(e){
      this.pageUrl = e;
    },
    curUsers(callback){
      this.usersFetch = callback;
    },
    triggerChange(e){
      this.usersFetch();
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
